<template>
  <custom-page>
    <group-card title="custom.page5.其他">

      <!--涂层-->
      <group-item label="custom.page5.涂层">
        <el-radio-group v-model="form.coating">
          <el-radio :label="c.otherObj.class.value" v-for="(c,i) in source.coating" :key="i">{{ $lang(c, "title") }}
          </el-radio>
        </el-radio-group>
      </group-item>

      <!--是否充氮-->
      <group-item label="custom.page5.是否充氮">
        <el-switch v-model="form.isNitrogenFilling"></el-switch>
      </group-item>

    </group-card>

    <div class="action-bar">
      <el-button type="" class="btn" @click="handlePrevClick">{{ $t("common.actions.pre") }}</el-button>
      <el-button type="primary" class="btn" @click="handleNextClick">{{ $t("common.actions.next") }}</el-button>
    </div>
  </custom-page>
</template>

<script>
import CustomPage from "@/views/Custom/components/CustomPage";
import GroupCard from "@/views/Custom/components/GroupCard";
import GroupItem from "@/views/Custom/components/GroupItem";
import mixin from "@/views/Custom/components/mixins/mixins";
import {mapGetters} from "vuex";
import {generatePartOtherObj} from "@/utils";

export default {
  name: "page5",
  components: {GroupItem, GroupCard, CustomPage},
  data() {
    return {
      form: {
        coating: "0",
        isNitrogenFilling: false,
      },
      source: {
        coating: []
      },
    }
  },
  mixins: [mixin],
  computed: {
    ...mapGetters(["customInfo"]),
  },
  mounted() {
    this.getCoatingList();
  },
  methods: {
    getCoatingList() {
      let params = {
        module: this.module,
        supplierId: this.customInfo.supplierId
      }
      this.$req.get("/client/goods/part/matchCoat", params).then(res => {
        res.forEach(generatePartOtherObj)
        this.source.coating = res;
      })

    },
    beforeNext(){
      this.$store.commit("SET_CUSTOM_INFO", this.form)
      return true;
    },
  },
}
</script>

<style scoped lang='scss'>

.action-bar {
  text-align: center;

  .btn {
    width: 330px;
  }
}
</style>